<template>
  <div class="outside">
    <!-- <banner /> -->
    <div class="top-banner">
      <img src="../../assets/img/preventiveProtection/banner.jpg" alt="" srcset="">
    </div>
    <div class="introduction">
      <div class="edition_heart">
        <div class="title">文物预防性保护部简介</div>
        <div class="titleEng">Introduction to the Department of Preventive Protection of Cultural Relics</div>
        <el-row>
          <el-col :span="24">
            <div class="content">
              山东文保在多年的文物保护修复实践当中，对文物本体材质及环境因素诱发的文物病害有了深刻的了解。我们着眼于文物本体，以实地调研与监测为依据，创建
              适宜不同质地文物的展藏环境。延缓材质病害的发生，绝不为了调控而调控，不为了监测而监测。
            </div>
            <div class="content">
              为了给经过保护的文物提供更加稳定、洁净的储藏环境，山东文保自2018年后逐渐开展了馆藏文物预防性保护业务
              。通过有效的质量管理、监测、评估、调控
              干预，最大程度的抑制各种环境因素变化对藏品的危害。
            </div>
            <div class="content">
              山东文保注重绿色预防性的概念，实时感知文物储藏环境的动态变化，实时感知文物材质病害的发生几率，让用户买得起用得起，提前预警，提前保护。
            </div>
          </el-col>
        </el-row>

        <div class="photos">
          <el-row>
            <el-col :span="8" v-for="(it, index) in introductionImg" :key="index">
              <img :src="it.imgUrl" alt="" />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="repair_case">
      <div class="edition_heart">
        <div class="title">场景展示</div>
        <div class="titleEng">Scenario shows</div>
        <div class="photos">
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
          <swiper ref="mySwiper" :options="swiperOptions" :autoplay="true" class="swiper">
            <swiper-slide v-for="(item, index) in sceneImg" :key="index" class="swiper_slide">
              <div class="repair_case_photos">
                <img ref="swiperImg" :src="item.imgUrl" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from '/src/components/banner.vue'
// vue-awesome-swiper@4.x 版本首字母 S 要用大写
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'RepairProcessDetails',
  components: { banner, Swiper, SwiperSlide },
  data() {
    return {
      introductionImg: [
        {
          imgUrl: require('/src/assets/img/preventiveProtection/Protection1.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/preventiveProtection/Protection2.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/preventiveProtection/Protection3.jpg'),
        },
      ],
      sceneImg: [
        {
          imgUrl: require('/src/assets/img/preventiveProtection/scene1.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/preventiveProtection/scene2.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/preventiveProtection/scene3.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/preventiveProtection/scene4.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/preventiveProtection/scene5.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/preventiveProtection/scene6.jpg'),
        },
      ],
      isMounted: false,
      swiperOptions: {
        slidesPerColumnFill: 'row',
        slidesPerColumn: 2,
        slidesPerView: 3, //一行显示3个
        spaceBetween: 10, //间隔10
        slidesPerGroup: 2,
        freeMode: true,
        speed: 1000, //滑动速度
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // loop: true,
        // autoplay: {
        //   //这个参数很重要,当外力干扰轮播图时，它能够继续实现自我滚动
        //   disableOnInteraction: false,
        //   delay: 3000, //5秒切换一次
        // },
      },
    }
  },
  mounted() {
    this.isMounted = true
  },
  computed: {
    customswiper() {
      let swiper
      if (this.isMounted) swiper = this.$refs.mySwiper.$swiper
      return swiper
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.outside {
  background-color: #F9F7F3;
}

.top-banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 1920px;
    height: 400px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 921px) {
  .edition_heart {
    width: 62.5%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }
  }

  .repair_case {
    .title {
      padding-top: 70px;
      font-size: 32px;
    }

    .swiper-button-next {
      top: 30px;
    }

    .swiper-button-prev {
      top: 30px;
      left: 95%;
    }

    .swiper_slide {
      // height: 270px;
      display: flex;
      align-items: center;
    }

    .repair_case_photos {
      padding: 0px 0 0px;
    }

    .photos {
      padding-bottom: 95px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .edition_heart {
    width: 80%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }
  }

  .repair_case {
    .title {
      padding-top: 125px;
      font-size: 32px;
    }

    .swiper-button-next {
      top: 30px;
    }

    .swiper-button-prev {
      top: 30px;
      left: 95%;
    }

    .swiper_slide {
      // height: 270px;
      display: flex;
      align-items: center;
    }

    .repair_case_photos {
      padding: 87px 0 95px;
    }

    .photos {
      padding-bottom: 95px;
    }
  }
}

@media screen and (max-width: 768px) {
  .top-banner {
    display: none;
  }

  .edition_heart {
    width: 90%;
  }

  .outside {
    padding-top: 60px;
  }

  .introduction {
    .title {
      padding-top: 20px;
    }
  }

  .edition_heart {
    padding-top: 0px;
  }

  .repair_case {
    .title {
      padding-top: 30px;
      font-size: 24px;
    }

    .swiper-button-next {
      top: 30px;
    }

    .swiper-button-prev {
      top: 30px;
      left: 90%;
    }

    .photos {
      padding-bottom: 10px;
    }

    .swiper_slide {
      // height: 270px;
      display: flex;
      align-items: center;
    }

    .repair_case_photos {
      padding: 0;
    }
  }

  ::v-deep .edition_heart {
    padding-top: 30px;
  }
}

* {
  margin: 0;
  padding: 0;
}

.outside {
  .introduction {
    width: 100%;

    .title {
      padding-top: 80px;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      color: #333;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .content {
      margin-top: 24px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #666;
      line-height: 30px;
      text-indent: 2em;
    }

    .photos {
      margin: 30px 0 0;

      img {
        width: 95%;
        height: auto;
        border: 5px solid rgba(255, 255, 255, 0.4);
      }
    }
  }

  .repair_case {
    width: 100%;
    height: auto;
    color: #333;
    position: relative;

    .title {
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .photos {
      // padding-bottom: 95px;
      --swiper-navigation-color: #fff;
      /* 单独设置按钮颜色 */
      --swiper-navigation-size: 16px;
      /* 设置按钮大小 */
      position: relative;

      .swiper-button-next {
        position: absolute;
        // top: 30px;
      }

      .swiper-button-prev {
        position: absolute;
        // top: 30px;
        // left: 95%;
      }

      .swiper {
        width: 100%;
        height: auto;
        padding: 45px 0px 10px;

        .swiper_slide {
          // height: 270px;
          display: flex;
          align-items: center;
        }
      }

      .repair_case_photos {
        width: 100%;
        cursor: pointer;

        img {
          width: 95%;
          height: auto;
          border: 5px solid rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  // 版心
  .edition_heart {
    // width: 62.5%;
    margin: 0px auto;
    position: relative;
  }
}

// ::v-deep .swiper-container {
//   // width: 100%;
//   // height: 580px;
//   .swiper-slide {
//     text-align: center;

//     /* Center slide text vertically */
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: -webkit-flex;

//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     -webkit-justify-content: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     -webkit-align-items: center;
//   }
// }
</style>
